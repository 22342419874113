import { forwardRef, useState, useRef, useEffect } from "react";
import styles from "./PaymentsModal.module.css";
import { IoMdClose } from "react-icons/io";
import SubmissionConfirmationPopup from "../submission-confirmation-popup/SubmissionConfirmationPopup";
import { postFormResponses } from "../../../../beta-home/services/network/post-form-response-api";
import { getFormByFormId } from "../../../../beta-home/services/network/get-form-by-formId";
import { getPaymentInfo } from "../../../../beta-home/services/network/get-payment-info";

import { Snackbar, Alert } from "@mui/material";

const PaymentsModal = forwardRef(({ formId, invoice, name }, ref) => {
  // const [file, setFile] = useState(null);

  const [responses, setResponses] = useState({
    question_id: "",
    type: "FILE_UPLOAD",
    file_url: "",
    text: "",
  });

  const fileInputRef = useRef(null);

  const formRef = useRef();

  function handleUploadClick() {
    fileInputRef.current.click();
  }
  function handleCancel() {
    fileInputRef.current.value = null;
    setResponses((prevValue) => ({ ...prevValue, file_url: "" }));
  }

  const submisssionConfirmationRef = useRef(null);

  const [formData, setFormData] = useState();
  const [paymentInfo, setPaymentInfo] = useState();
  const [loadingState, setLoading] = useState();
  const [submissionError, setSubmissionError] = useState({
    missingFile: false,
    formSubmissionError: false,
  });

  useEffect(() => {
    getFormByFormId(
      (response) => {
        setFormData(response);
      },
      (error) => {
        console.error(error);
      },
      formId
    );
  }, [formId]);

  useEffect(() => {
    setLoading(true);
    getPaymentInfo(
      (response) => {
        setPaymentInfo(response.payment_info);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      }
    );
  }, []);

  const paymentFormData = formData && formData;

  const fileUploadQuestion = paymentFormData?.questions.find(
    (question) => question.type === "FILE_UPLOAD"
  );

  function responseForAllQuestions() {
    const newResponses = [];

    paymentFormData?.questions.forEach((question) => {
      if (question.type === "CHOICE" && question.visibility === false) {
        newResponses.push({
          question_id: question._id,
          type: "CHOICE",
          choice: question.choices[0]._id,
        });
      } else if (question.type !== "CHOICE" && question.visibility === false) {
        newResponses.push({
          question_id: question._id,
          type: "TEXT",
          text: "",
        });
      }
    });

    const combinedResponses = [...[responses], ...newResponses];

    return combinedResponses;
  }

  const handleFileUploadInputChange = (e) => {
    if (e.target.files.length > 0) {
      setSubmissionError((prevError) => ({
        ...prevError,
        missingFile: false,
      }));
    }

    setResponses((prevValue) => {
      if (prevValue?.file_url) {
        URL.revokeObjectURL(prevValue.file_url);
      }
      return {
        ...prevValue,
        question_id: e.target.id,
        file_url: URL.createObjectURL(e.target.files[0]),
      };
    });
  };

  const handleRefNumberChange = (e) => {
    setResponses((prevValue) => ({
      ...prevValue,
      question_id: e.target.id,
      text: e.target.value,
    }));
  };

  function handleSubmit(event) {
    const formId = paymentFormData._id;
    event.preventDefault();

    const formResponses = responseForAllQuestions();

    if (fileUploadQuestion.is_required === true && !responses.file_url) {
      setSubmissionError((prevError) => ({ ...prevError, missingFile: true }));

      return;
    }

    postFormResponses(
      formId,
      formResponses,
      (successData) => {
        const inputs = formRef.current.querySelectorAll(
          "input, textarea, select"
        );
        fileInputRef.current.value = null;

        setResponses({
          question_id: "",
          type: "FILE_UPLOAD",
          file_url: "",
          text: "",
        });

        ref.current.close();

        submisssionConfirmationRef.current.showModal();
      },
      (errorMessage) => {
        setSubmissionError((prevError) => ({
          ...prevError,
          formSubmissionError: true,
        }));
        console.error("Form submission failed:", errorMessage);
      }
    );
  }

  return (
    <dialog className={styles.paymentModal} ref={ref} open={false}>
      <div className={styles.modalHeader}>
        <div className={styles.title}>Pay using the details given below</div>
        <div className={styles.closeButton} onClick={() => ref.current.close()}>
          <IoMdClose />
        </div>
      </div>
      <div className={styles.paymentDetailsCard}>
        <div className={styles.scan}>
          <div>Scan QR code</div>
          <div className={styles.scanImage}>
            <img src={paymentInfo ? paymentInfo.qr_code_url : ""} />
          </div>
        </div>
        <div className={styles.line}></div>
        <div className={styles.or}>OR</div>
        <div className={styles.bankDetails}>
          <div>Bank account details</div>
          <div className={styles.details}>
            <ul className={styles.labels}>
              {paymentInfo?.account_number && <li>Account Number:</li>}
              {paymentInfo?.ifsc_code && <li>IFSC Code:</li>}
              {paymentInfo?.account_holder_name && <li>Account Holder:</li>}
            </ul>
            <ul className={styles.values}>
              <li>{paymentInfo?.account_number}</li>
              <li>{paymentInfo?.ifsc_code}</li>
              <li>{paymentInfo?.account_holder_name}</li>
            </ul>
          </div>
        </div>
      </div>
      <form ref={formRef} onSubmit={handleSubmit}>
        {paymentFormData &&
          paymentFormData.questions.map((question, index) => {
            return (
              question.visibility && (
                <div key={index}>
                  <input
                    placeholder="Enter payment reference number"
                    id={question._id}
                    name="reference number"
                    type="text"
                    className={styles.refNumber}
                    onChange={handleRefNumberChange}
                    required
                    maxLength="20"
                  />
                  <div className={styles.upload}>
                    {responses.file_url ? (
                      <div className={styles.previewImage}>
                        <IoMdClose
                          className={styles.close}
                          onClick={handleCancel}
                        />
                        <img
                          className={styles.image}
                          src={responses.file_url}
                          alt="Uploaded Screenshot"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <input
                      type="file"
                      ref={fileInputRef}
                      className={styles.uploadFiles}
                      id={question._id}
                      onChange={handleFileUploadInputChange}
                      // required
                      accept="image/jpeg, image/png"
                    />
                    <label
                      onClick={handleUploadClick}
                      className={styles.uploadButton}
                    >
                      {responses.file_url ? "Change Image" : "Upload Image"}
                    </label>
                    <p
                      className={`${styles.text} ${
                        submissionError.missingFile && `${styles.errorText}`
                      }`}
                    >
                      *Upload your payment screenshot (max size: 5 MB)
                    </p>
                  </div>
                </div>
              )
            );
          })}
        <div className={styles.submit}>
          <button className={styles.submitBtn}>SUBMIT</button>
        </div>
      </form>
      <SubmissionConfirmationPopup
        title={"Your Payment is under verification"}
        description={
          "After your payment is confirmed, you'll be able to join the batch"
        }
        identifiers={["Name", "Invoice Number", "Amount", "Payment Status"]}
        details={[
          name,
          invoice.invoice_number,
          "₹" + invoice.total_amount,
          "Pending Verification",
        ]}
        okButton
        ref={submisssionConfirmationRef}
      />

      <Snackbar
        open={submissionError.formSubmissionError}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() =>
          setSubmissionError((prevError) => ({
            ...prevError,
            formSubmissionError: false,
          }))
        }
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Error submitting form! Please try again.
        </Alert>
      </Snackbar>
    </dialog>
  );
});

export default PaymentsModal;
