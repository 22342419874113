import { forwardRef } from "react";
import { VscClose } from "react-icons/vsc";
import styles from "./ServiceModal.module.css";
import { useTheme } from "../../../../ThemeProvider.jsx";

const ServiceModal = forwardRef(function ServiceModal(
  { onClose, service },
  ref
) {
  const { color, templateStyle, templateClassName } = useTheme();

  return (
    <dialog
      ref={ref}
      className={`${styles.serviceModal} ${styles[templateClassName]}`}
    >
      <div className={styles.serviceModalContainer}>
        <div onClick={onClose} className={styles.close}>
          <button className={styles.closeBtn}>
            <VscClose />
          </button>
        </div>
        <img src={service.image_url || ""} alt="" />
        <div className={styles.content}>
          <div style={{ color: color }} className={styles.title}>
            {service.title || ""}
          </div>
          <div className={styles.desc}>{service.description || ""}</div>
          <div className={styles.benefits}>
            <span>Benefits</span>
            {service.benefits || ""}
          </div>
        </div>
      </div>
    </dialog>
  );
});

export default ServiceModal;
