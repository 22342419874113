import { useRef, useState, useEffect } from "react";
import Batches from "../layouts/Batches/Batches";
import AboutUs from "../layouts/Footer/AboutUs";
import Gallery from "../layouts/Gallery/Gallery";
import Header from "../layouts/Header/Header";
import PreviewImage from "../layouts/PreviewImage/PreviewImage";
import Services from "../layouts/Services/Services";
import Testimonials from "../layouts/Testimonials/Testimonials";
import WhyUs from "../layouts/WhyUs/WhyUs";
import ServiceRequset from "../layouts/ServiceRequest/ServiceRequest";
import MenuModal from "../layouts/modals/Menu-Modal/MenuModal";
import getLandingPageData from "../../services/helper/get_landing_page_data";
import { ThemeProvider } from "../ThemeProvider";

import styles from "./BetaHome.module.css";

export default function BetaHome() {
  const landingPageData = getLandingPageData(window.pageData);

  // const [loading, setLoading] = useState(!landingPageData);
  const loading = !landingPageData;

  const template = "midnightzen";

  const {
    welcomeSection,
    business,
    salesPerson,
    socialLinks,
    servicesList,
    testimonialsList,
    whyUsList,
    gallery,
    theme,
    formIds,
  } = landingPageData;

  const menuModalRef = useRef(null);
  const servicesRef = useRef(null);
  const batchesRef = useRef(null);
  const galleryRef = useRef(null);
  const testimonialRef = useRef(null);

  function handleScroll(ref) {
    menuModalRef.current.close();
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }

  const fontName = (theme) => {
    return theme.toLowerCase().replace(/\s+/g, "");
  };

  const fontClassName = fontName(theme.fontStyle);

  return (
    <div>
      <ThemeProvider>
        <main
          className={
            theme.fontStyle ? `${styles[fontClassName]}` : `${styles.nunito}`
          }
        >
          {loading ? (
            <div className="loading">
              <div className="loader"></div>
            </div>
          ) : (
            <div>
              <MenuModal
                ref={menuModalRef}
                sales={salesPerson}
                onClose={() => menuModalRef.current.close()}
                formId={formIds.leadFormId}
              />
              <Header
                businessDetails={business}
                sales={salesPerson}
                onOpen={() => menuModalRef.current.showModal()}
                formId={formIds.leadFormId}
                template={template}
              />
              <PreviewImage
                welcomeSectionDetails={welcomeSection}
                sales={salesPerson}
                template={template}
              />
              {servicesList.length > 0 && (
                <Services
                  ref={servicesRef}
                  servicesList={servicesList}
                  template={template}
                />
              )}

              {whyUsList.length > 0 && (
                <WhyUs whyUsList={whyUsList} template={template} />
              )}
              <Batches
                ref={batchesRef}
                template={template}
                formId={formIds.newMemberFormId}
              />
              {gallery.length > 0 && (
                <Gallery
                  ref={galleryRef}
                  photosList={gallery}
                  template={template}
                />
              )}
              {testimonialsList.length > 0 && (
                <Testimonials
                  ref={testimonialRef}
                  testimonialsList={testimonialsList}
                  template={template}
                />
              )}

              <ServiceRequset
                formId={formIds.appointmentFormId}
                template={template}
              />

              <AboutUs
                businessDetails={business}
                sales={salesPerson}
                socialLinks={socialLinks}
              />
            </div>
          )}
        </main>
      </ThemeProvider>
    </div>
  );
}
