import Features from "../layouts/features/Features";
import Footer from "../layouts/footer/Footer";
import GetStarted from "../layouts/landing/GetStarted";
import Header from "../layouts/header/Header";
import {awsRoles, uploadFileToAWS} from "../../../shared/aws";

export default function Home() {
    const handleFileInput = (e) => {
        uploadFileToAWS(e.target.files[0], awsRoles.MEMBER)
    };
  return (
    <>
        <main>
            <Header/>
            <input type="file" accept="image/*" onChange={handleFileInput}/>
            <GetStarted/>
            <Features/>
            <Footer/>
        </main>
    </>
  );
}
