import React, { createContext, useContext } from "react";
import getLandingPageData from "../services/helper/get_landing_page_data";
import { createTheme } from "@mui/material";
import { ThemeProvider as FormThemeProvider } from "@mui/material";
import { useLocation } from "react-router-dom";

const landingPageData = getLandingPageData(window.pageData);

const { theme } = landingPageData;

// const template = "simpleui";

const themeColor = theme.color;

const themes = [
  {
    templateName: "defaultTheme",
    color: themeColor,
    templateClassName: "defaultTheme",
  },
  {
    templateName: "midnightzen",
    color: "#496CE6",
    // templateStyle: "template-1",
    templateClassName: "midnightzen",
  },
  {
    templateName: "simpleui",
    color: "#496CE6",
    // templateStyle: "template-2",
    templateClassName: "simpleui",
  },
  {
    templateName: "elevate",
    color: "#496CE6",
    // templateStyle: "template-1",
    templateClassName: "elevate",
  },
  {
    templateName: "edgefusion",
    color: "#496CE6",
    // templateStyle: "template-2",
    templateClassName: "edgefusion",
  },
];

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const { search } = useLocation();

  const template =
    new URLSearchParams(search).get("template") || "defaultTheme";

  const activeTheme = template
    ? themes.find((theme) => theme.templateName === template)
    : themes[0];

  const muiTheme =
    activeTheme.templateName === "midnightzen"
      ? formThemes.dark
      : formThemes.default;

  return (
    <ThemeContext.Provider value={activeTheme}>
      <FormThemeProvider theme={muiTheme}>{children}</FormThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);

export const formThemes = {
  default: createTheme({
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#212121",
            backgroundColor: "#fff",
            "&.Mui-focused": {
              color: "#757575",
            },
            "&.Mui-disabled": { color: "#757575" },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#424242",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#383838",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#383838",
            },
            "& .MuiSelect-select": {
              color: "#424242",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: "#212121",
            backgroundColor: "#fff",
            "&:hover": {
              backgroundColor: "#cccccc",
            },
            "&.Mui-selected": {
              backgroundColor: "#b2b2b2",
            },
            "&.Mui-selected:hover": {
              backgroundColor: "#999999",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: "#212121",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: "#fff",
          },
        },
      },
    },
  }),
  dark: createTheme({
    typography: {
      fontFamily: "'Nunito',  sans-serif",
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#fff",
            backgroundColor: "#000",
            "&.Mui-focused": {
              color: "#fff",
            },
            "&.Mui-disabled": { color: "#fff" },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            color: "#fff",
            "&.Mui-disabled": {
              color: "#fff",
              WebkitTextFillColor: "#fff",
              borderColor: "#fff",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#fff",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ccc",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#fff",
            },
            "& .MuiSelect-select": {
              color: "#fff",
            },
            "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
              borderColor: "#fff",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: "#fff",
            backgroundColor: "#212121",
            "&:hover": {
              backgroundColor: "#333333",
            },
            "&.Mui-selected": {
              backgroundColor: "#444444",
            },
            "&.Mui-selected:hover": {
              backgroundColor: "#555555",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: "#fff",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: "#212121",
          },
        },
      },
    },
  }),
};
