import { forwardRef } from "react";
import customer from "../../../assets/customer.png";
import styles from "./AccountPopup.module.css";
import { IoMdClose } from "react-icons/io";
import { setMemberDataLocal } from "../../../../shared/helper";

import { googleLogout } from "@react-oauth/google";
import blankProfile from "../../../assets/blank_profile.png";

import { useState, useEffect } from "react";
import { getCustomerById } from "../../../services/profile-page-api";
import { formatDateTime } from "../../../../shared/helper";
import getLandingPageData from "../../../../beta-home/services/helper/get_landing_page_data";

const AccountPopup = forwardRef(function AccountPopup(
  { onClose, memberData, setMemberData },
  ref
) {
  const landingPageData = getLandingPageData(window.pageData);

  const { subdomain } = landingPageData;

  function handleGoogleLogout() {
    googleLogout();
    setMemberDataLocal();

    if (window.location.hostname.includes("localhost")) {
      window.location.href = `http://localhost:5001/auth?sd=${subdomain}`;
    } else {
      window.location.href = `https://yogawav.com/auth?sd=${subdomain}`;
    }
  }

  const [memberInfo, setMemberInfo] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the member data from API
    getCustomerById(
      (response) => {
        setMemberInfo(response.customer);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.mainContainer} ref={ref}>
        <IoMdClose className={styles.closeBtn} onClick={onClose} />
        <div className={styles.popupHeader}>Account Info</div>
        <div className={styles.details}>
          {memberInfo.image ? (
            <img src={memberInfo.image} alt="" />
          ) : (
            <img src={blankProfile} />
          )}
          <span className={styles.name}>{memberInfo.name}</span>
          <span>Member ID: {memberInfo.customer_id}</span>
          <span>Joined on: {formatDateTime(memberInfo.registered_on)}</span>
          <span>{memberInfo.email}</span>
        </div>
        <button className={styles.signoutBtn} onClick={handleGoogleLogout}>
          Sign Out
        </button>
      </div>
    </div>
  );
});

export default AccountPopup;
