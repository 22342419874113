import AllServices from "../modals/Services/All-Services/AllServices.jsx";
import ServiceModal from "../modals/Services/Service-Modal/ServiceModal.jsx";
import { ShowAll, ReadMore } from "../../../../shared/components/utils/utils";

import stylesTemplate1 from "./templates/template-1/Services.module.css";
import stylesTemplate2 from "./templates/template-2/Services.module.css";

import { forwardRef, useRef, useState } from "react";
import { useTheme } from "../../ThemeProvider.jsx";

const Services = forwardRef(function Services({ servicesList, template }, ref) {
  const { color, templateName, templateClassName } = useTheme();

  const styles =
    templateName === "simpleui" || templateName === "edgefusion"
      ? stylesTemplate2
      : stylesTemplate1;

  const showAllServicesRef = useRef(null);
  const serviceModalRef = useRef(null);

  const [selectedService, setSelectedService] = useState(null);

  function handleServiceModalOpen(service) {
    serviceModalRef.current.showModal();
    setSelectedService(service);
  }

  function handleServiceModalClose() {
    serviceModalRef.current.close();
  }

  return (
    <>
      <ServiceModal
        ref={serviceModalRef}
        onClose={handleServiceModalClose}
        service={selectedService != null ? selectedService : {}}
      />

      <div
        ref={ref}
        className={`${styles.services} ${styles[templateClassName]}`}
        id="services"
      >
        <div className={styles.titleHeader}>
          <h2>Our Services & Benefits</h2>
        </div>
        <ul className={styles.servicesList}>
          {servicesList.map((service, index) => {
            return (
              index < 6 && (
                <li
                  key={`service-${index}`}
                  onClick={() => handleServiceModalOpen(service)}
                  className={styles.serviceListCard}
                >
                  <ServiceCard
                    imageUrl={service.image_url || ""}
                    title={service.title || ""}
                    desc={service.description || ""}
                    template={template}
                  />
                </li>
              )
            );
          })}
        </ul>
      </div>
    </>
  );
});

export function ServiceCard({ imageUrl, title, desc, template }) {
  const { color, templateName, templateClassName } = useTheme();

  const styles =
    templateName === "simpleui" || templateName === "edgefusion"
      ? stylesTemplate2
      : stylesTemplate1;

  return (
    <div className={`${styles.serviceCard} ${styles[templateClassName]}`}>
      <img src={imageUrl} alt="" />

      <div className={styles.content}>
        <div className={styles.serviceContent}>
          <span className={styles.title}>{title}</span>
          <span className={styles.desc}>{desc}</span>
        </div>
        <div>
          <span className={styles.readMore}>
            <ReadMore />
          </span>
        </div>
      </div>
    </div>
  );
}

export default Services;
