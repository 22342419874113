import { ShowAll } from "../../../../shared/components/utils/utils";
import { GoClockFill } from "react-icons/go";

import stylesTemplate1 from "./templates/template-1/Batches.module.css";
import stylesTemplate2 from "./templates/template-2/Batches.module.css";

import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAllBatches } from "../../../services/network/get-batches-api.js";
import CircularProgress from "@mui/material/CircularProgress";
import BatchesModal from "../modals/BatchesModal/BatchesModal.jsx";
import { useRef } from "react";
import { useTheme } from "../../ThemeProvider.jsx";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaClock, FaLocationDot } from "react-icons/fa6";
import { MdTimer } from "react-icons/md";
import { BsExclamationCircle } from "react-icons/bs";
import { formatTime } from "../../../../shared/helper.js";

const Batches = forwardRef(function Batches(
  { isBatchesPage, template, formId, batchFormId },
  ref
) {
  const { color, templateName, templateClassName } = useTheme();

  const styles =
    templateName === "defaultTheme" ? stylesTemplate1 : stylesTemplate2;

  const [batchesData, setBatchesData] = useState([]);
  const [loading, setLoading] = useState(true);

  let className = `${styles.batches} ${styles[templateClassName]}`;
  if (isBatchesPage) {
    className += ` ${styles.batchesPage}`;
  } else {
    className += "";
  }

  const navigate = useNavigate();

  useEffect(() => {
    getAllBatches(
      (response) => {
        setBatchesData(response.batches);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <>
      {loading ? (
        <div className="styles.loaderWrapper">
          <CircularProgress thickness={4} />
        </div>
      ) : (
        batchesData.length > 0 && (
          <div ref={ref} id="batches" className={className}>
            <>
              {isBatchesPage ? (
                <>
                  <div className={styles.titleHeader}>
                    <div>Batches</div>
                  </div>
                  <ul className={styles.batchList}>
                    {batchesData.map((batch, index) => {
                      return (
                        <li key={`batch-${index}`}>
                          <BatchCard
                            batchData={batch}
                            isBatchesPageCard={isBatchesPage}
                            formId={batchFormId}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                <>
                  <div className={styles.titleHeader}>
                    <h2>Batches</h2>
                    <span
                      className={`${
                        batchesData.length > 3
                          ? `${styles.showAll}`
                          : `${styles.noShowAll}`
                      }`}
                      onClick={() => navigate("/batches")}
                      style={{ color: color }}
                    >
                      <ShowAll />
                    </span>
                  </div>
                  <ul className={styles.batchList}>
                    {batchesData.map((batch, index) => {
                      return (
                        index < 3 && (
                          <li key={`batch-${index}`}>
                            <BatchCard
                              batchData={batch}
                              isBatchesPageCard={isBatchesPage}
                              template={template}
                              formId={formId}
                            />
                          </li>
                        )
                      );
                    })}
                  </ul>
                </>
              )}
            </>
          </div>
        )
      )}
    </>
  );
});

function BatchCard({ batchData, isBatchesPageCard, template, formId }) {
  const { color, templateName, templateClassName } = useTheme();

  const styles =
    templateName === "defaultTheme" ? stylesTemplate1 : stylesTemplate2;

  let className = styles.batchCard;
  if (isBatchesPageCard) {
    className += ` ${styles.batchesPage}`;
  } else {
    className += "";
  }

  const availableSeats =
    batchData.general_details.max_customers - batchData.customer_count;

  const dayNames = {
    MON: "Monday",
    TUE: "Tuesday",
    WED: "Wednesday",
    THU: "Thursday",
    FRI: "Friday",
    SAT: "Saturday",
    SUN: "Sunday",
  };

  const formattedDays =
    batchData.schedule.days_active &&
    batchData.schedule.days_active.map((day) => dayNames[day]).join(" | ");

  function formattedDate(millis) {
    const date = new Date(millis);

    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  }

  const batchesModalRef = useRef();

  return (
    <div className={className}>
      <div className={styles.batchDetails}>
        <img className={styles.image} src={batchData.image_url} alt="" />
        {availableSeats <= 0 && (
          <div className={styles.batchFull}>
            <BsExclamationCircle />
            <span className={styles.text}>Batch Full</span>
          </div>
        )}
        <div
          className={`${
            availableSeats <= 0
              ? `${styles.batchFullContent}`
              : `${styles.batchContent}`
          }`}
        >
          {console.log(batchData)}
          <div style={{ borderColor: color }} className={styles.title}>
            {batchData.general_details.title}
          </div>
          <div className={styles.desc}>
            {batchData.general_details.description}
          </div>
          <div className={styles.aboutBatch}>
            {batchData.schedule.session_frequency === "one-time" ? (
              <>
                <div>One Time: 1 class</div>
                <div>Day: {formattedDate(batchData.schedule.date)}</div>
              </>
            ) : batchData.schedule.session_frequency === "weekly" ? (
              <>
                <div className={styles.weeklyClasses}>
                  Weekly: {batchData?.schedule?.days_active.length} Classes
                </div>
                <div className={styles.days}>Days: {formattedDays}</div>
              </>
            ) : (
              <>
                <div>Monthly: 1 class</div>
                <div>Day: {formattedDate(batchData.schedule.start_date)}</div>
              </>
            )}

            <div className={styles.time}>
              Time: {formatTime(batchData.schedule.time)}
            </div>
            <div className={styles.duration}>
              Duration: {batchData.schedule.duration} Mins
            </div>
            <div className={styles.location}>
              Location: {batchData.location.mode}
            </div>
          </div>
          <div className={styles.aboutBatchMobile}>
            {batchData.schedule.session_frequency === "one-time" ? (
              <>
                <div className={`${styles.sessionFrequency} `}>
                  One Time: 1 class
                </div>
                <div className={`${styles.day} ${styles.onetime}`}>
                  {formattedDate(batchData.schedule.date)}
                </div>
              </>
            ) : batchData.schedule.session_frequency === "weekly" ? (
              <>
                <div className={styles.sessionFrequency}>
                  {batchData?.schedule?.days_active.length} Classes/Week
                </div>
                <ul className={styles.days}>
                  {batchData?.schedule?.days_active?.map((day, index) => (
                    <li key={index} className={styles.day}>
                      {day.toLowerCase()}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <>
                <div className={styles.sessionFrequency}>Monthly: 1 class</div>
                <div className={`${styles.day} ${styles.monthly}`}>
                  {formattedDate(batchData.schedule.start_date)}
                </div>
              </>
            )}
            <div className={styles.classDetails}>
              <div className={styles.time}>
                <FaClock style={{ color: color }} />
                {formatTime(batchData.schedule.time)}
              </div>
              <div className={styles.duration}>
                <MdTimer style={{ color: color }} />{" "}
                {batchData.schedule.duration} Mins
              </div>
              <div className={styles.mode}>
                <FaLocationDot style={{ color: color }} />{" "}
                {batchData.location.mode}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          availableSeats <= 0
            ? `${styles.bookingFullDetails}`
            : `${styles.bookingDetails}`
        }
      >
        <div className={styles.availableSeats}>
          {availableSeats <= 0
            ? "*This batch is currently full. Please contact us."
            : ` *Only ${availableSeats} seats left--join now`}
        </div>
        <div className={styles.pricing}>
          <div className={styles.batchPrice}>
            <span className={styles.originalPrice}>
              INR {batchData.pricing.amount}
            </span>
            <span className={styles.discountPrice}>
              INR {batchData.pricing.payable_amount}
            </span>
          </div>
          <div>
            {availableSeats <= 0 ? (
              <button
                style={{ backgroundColor: color }}
                className={styles.bookNowBtn}
              >
                Contact Us
              </button>
            ) : (
              <button
                style={{ backgroundColor: color }}
                onClick={() =>
                  batchesModalRef.current && batchesModalRef.current.showModal()
                }
                className={styles.bookNowBtn}
              >
                Book now
              </button>
            )}
            <BatchesModal
              templateClassName={templateClassName}
              formId={formId}
              batchData={batchData}
              ref={batchesModalRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Batches;
