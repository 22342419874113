import { forwardRef } from "react";
// import { MdClose } from "react-icons/md";
import { VscClose } from "react-icons/vsc";
import styles from "./WhyUsModal.module.css";
import { useTheme } from "../../../../ThemeProvider";

const WhyUsModal = forwardRef(function WhyUsModal({ onClose, whyUs }, ref) {
  const { templateClassName } = useTheme();

  return (
    <dialog
      ref={ref}
      className={`${styles.whyUsModal} ${styles[templateClassName]}`}
    >
      <div className={styles.whyUsModalContainer}>
        <div onClick={onClose} className={styles.close}>
          <VscClose />
        </div>
        <div className={styles.modalContent}>
          <img src={whyUs.image_url || ""} alt="" />
          <div className={styles.title}>{whyUs.title}</div>
          <div className={styles.desc}>{whyUs.description}</div>
        </div>
      </div>
    </dialog>
  );
});

export default WhyUsModal;
