import styles from "./ServiceRequestModal.module.css";
import { forwardRef, useRef } from "react";
import { IoClose } from "react-icons/io5";
import { useTheme } from "../../../ThemeProvider.jsx";
import SubmissionConfirmationPopup from "../../../../../member-dashboard/components/layouts/submission-confirmation-popup/SubmissionConfirmationPopup";
import { getFormByFormId } from "../../../../services/network/get-form-by-formId";
import { useState, useEffect } from "react";
import { postFormResponses } from "../../../../services/network/post-form-response-api";
import { formatTime } from "../../../../../shared/helper";
import { Snackbar, Alert, TextField } from "@mui/material";

const ServiceRequestModal = forwardRef(function ServiceRequestModal(
  { formId },
  ref
) {
  const { color, templateStyle, templateClassName } = useTheme();
  const submissionConfirmationRef = useRef();

  const [formData, setFormData] = useState();
  const [submissionError, setSubmissionError] = useState();
  const [responses, setResponses] = useState([]);

  const formRef = useRef();

  useEffect(() => {
    getFormByFormId(
      (response) => {
        setFormData(response);
      },
      (error) => {
        console.error(error);
      },
      formId
    );
  }, [formId]);

  const appointmentFormData = formData && formData;

  const handleChange = (event) => {
    const { id, value, type } = event.target;

    const nameQuestion =
      appointmentFormData &&
      appointmentFormData.questions.find(
        (question) => question.title === "Name"
      );

    const nameQuestionId = nameQuestion ? nameQuestion._id : null;

    if (type === "text" && id === nameQuestionId) {
      setSubmissionConfirmationData((prevResponses) => ({
        ...prevResponses,
        name: value,
      }));
    }

    if (type === "date") {
      setSubmissionConfirmationData((prevResponses) => ({
        ...prevResponses,
        date: value,
      }));
    }

    if (type === "time") {
      setSubmissionConfirmationData((prevResponses) => ({
        ...prevResponses,
        time: formatTime(value),
      }));
    }

    setResponses((prevResponses) => {
      const existingIndex = prevResponses.findIndex(
        (res) => res.question_id === id
      );

      const dateQuestion =
        appointmentFormData &&
        appointmentFormData.questions.find(
          (question) => question.type === "DATE"
        );

      const dateQuestionId = dateQuestion ? dateQuestion._id : null;

      const dateString =
        dateQuestionId && id === dateQuestionId && value.valueOf();

      const date = dateString && new Date(dateString);
      const millis = date && date.getTime();

      if (existingIndex !== -1) {
        const updatedResponses = [...prevResponses];
        updatedResponses[existingIndex] = {
          question_id: id,
          type: "TEXT",
          text: type === "date" ? millis : value,
        };
        return updatedResponses;
      } else {
        return [
          ...prevResponses,
          {
            question_id: id,
            type: "TEXT",
            text: type === "date" ? millis : value,
          },
        ];
      }
    });
  };

  const [submissionConfirmationData, setSubmissionConfirmationData] = useState({
    name: "",
    date: "",
    time: "",
  });

  function handleSubmit(event) {
    const formId = appointmentFormData._id;
    event.preventDefault();

    postFormResponses(
      formId,
      responses,
      (successData) => {
        setResponses([]);
        const inputs = formRef.current.querySelectorAll(
          "input, textarea, select"
        );
        inputs.forEach((input) => {
          input.value = "";
        });

        ref.current.close();

        submissionConfirmationRef.current.showModal();
      },
      (errorMessage) => {
        setSubmissionError(true);
        console.error("Form submission failed:", errorMessage);
      }
    );
  }

  return (
    <dialog
      className={`${styles.serviceRequestModal} ${styles[templateClassName]}`}
      ref={ref}
    >
      <div className={styles.close} onClick={() => ref.current.close()}>
        <button className={styles.closeButton}>
          <IoClose />
        </button>
      </div>
      <div className={styles.title}>Set up a call</div>
      <form ref={formRef} className={styles.form} onSubmit={handleSubmit}>
        {appointmentFormData &&
          appointmentFormData.questions.map((question, index) => {
            return (
              question.visibility && (
                <div className={styles.form} key={index}>
                  {question.type === "TEXT" && question.max_value > 100 ? (
                    <div className={styles.formInput}>
                      <textarea
                        placeholder={question.title}
                        maxLength={question.max_value || undefined}
                        className={styles.textarea}
                        onChange={handleChange}
                        required={question.is_required}
                        id={question._id}
                        label={question.title}
                      />
                    </div>
                  ) : question.type === "DATE" || question.type === "TIME" ? (
                    <div className={styles.formInput}>
                      <TextField
                        fullWidth
                        size="small"
                        label={question.title}
                        variant="outlined"
                        type={question.type.toLowerCase()}
                        required={question.is_required}
                        onChange={handleChange}
                        id={question._id}
                        InputLabelProps={{
                          shrink: true,
                          style: { fontFamily: "inherit", fontSize: "14px" },
                        }}
                        InputProps={{
                          inputProps: { type: "date" },
                          style: {
                            fontFamily: "inherit",
                            fontSize: "14px",
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <div className={styles.formInput}>
                      {" "}
                      <TextField
                        fullWidth
                        size="small"
                        label={question.title}
                        variant="outlined"
                        inputProps={{
                          maxLength: question.max_value || undefined,
                          pattern:
                            question.type === "NUMBER" ? "[0-9]*" : undefined,
                          minLength:
                            question.type === "NUMBER" ? "10" : undefined,
                        }}
                        type={
                          question.type === "NUMBER"
                            ? "tel"
                            : question.type.toLowerCase()
                        }
                        required={question.is_required}
                        onChange={handleChange}
                        id={question._id}
                        InputLabelProps={{
                          style: { fontFamily: "inherit", fontSize: "14px" },
                        }}
                        InputProps={{
                          style: {
                            fontFamily: "inherit",
                            fontSize: "14px",
                          },
                        }}
                        className={
                          question.max_value > 100 && question.type === "TEXT"
                            ? `${styles.textarea}`
                            : ""
                        }
                      />
                    </div>
                  )}
                </div>
              )
            );
          })}
        <div className={styles.button}>
          <button style={{ backgroundColor: color }} className={styles.submit}>
            Submit
          </button>
        </div>
      </form>
      <SubmissionConfirmationPopup
        ref={submissionConfirmationRef}
        templateClassName={templateClassName}
        title={"Your call has been scheduled"}
        description={
          "We're looking forward to speaking with you. Someone will be in touch at the arranged time. Thank you!"
        }
        identifiers={[
          "Name",
          submissionConfirmationData.date ? "Date" : "",
          submissionConfirmationData.time ? "Time" : "",
        ]}
        details={[
          submissionConfirmationData.name,
          submissionConfirmationData.date && submissionConfirmationData.date,
          submissionConfirmationData.time && submissionConfirmationData.time,
        ]}
        onClose={() =>
          setSubmissionConfirmationData({ name: "", date: "", time: "" })
        }
        closeButton
      />

      <Snackbar
        open={submissionError}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => setSubmissionError(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Error submitting form! Please try again.
        </Alert>
      </Snackbar>
    </dialog>
  );
});

export default ServiceRequestModal;
