import styles from "./BatchesModal.module.css";
import { forwardRef } from "react";
import { FiArrowLeft } from "react-icons/fi";

import { useState, useRef, useEffect } from "react";
import { MdCheck } from "react-icons/md";
import { useTheme } from "../../../ThemeProvider.jsx";

import { postFormResponses } from "../../../../services/network/post-form-response-api";
import SubmissionConfirmationPopup from "../../../../../member-dashboard/components/layouts/submission-confirmation-popup/SubmissionConfirmationPopup";
import { getFormByFormId } from "../../../../services/network/get-form-by-formId";
import { getPaymentInfo } from "../../../../services/network/get-payment-info";

import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
} from "@mui/material";

import { IoMdClose } from "react-icons/io";

const BatchesModal = forwardRef(function BatchesModal(
  { batchData, formId },
  ref
) {
  const submissionConfirmationRef = useRef();
  const [formData, setFormData] = useState();
  const [paymentInfo, setPaymentInfo] = useState();
  const [loading, setLoading] = useState();
  const [submissionError, setSubmissionError] = useState({
    missingFile: false,
    formSubmissionError: false,
  });

  const [file, setFile] = useState({
    question_id: "",
    type: "FILE_UPLOAD",
    file_url: "",
    text: "",
  });

  const fileInputRef = useRef(null);

  function handleUploadClick() {
    fileInputRef.current.click();
  }

  function handleCancel() {
    fileInputRef.current.value = null;
    setFile((prevValue) => ({ ...prevValue, file_url: "" }));
  }

  const formRef = useRef();

  useEffect(() => {
    setLoading(true);
    getFormByFormId(
      (response) => {
        setFormData(response);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      },
      formId
    );
  }, [formId]);

  useEffect(() => {
    setLoading(true);
    getPaymentInfo(
      (response) => {
        setPaymentInfo(response.payment_info);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      }
    );
  }, []);

  const memberFormData = formData && formData;

  const [responses, setResponses] = useState([]);

  const gender =
    memberFormData &&
    memberFormData.questions.find((question) => question.type === "CHOICE");

  const genderId = gender && gender._id;

  const payment =
    memberFormData &&
    memberFormData.questions.find(
      (question) => question.type === "FILE_UPLOAD"
    );

  const paymentId = payment && payment._id;

  const batchTitle =
    memberFormData &&
    memberFormData.questions.find((question) => question.title === "Batch");

  function responseForAllQuestions() {
    const newResponses = [];

    newResponses.push(file);

    newResponses.push({
      question_id: batchTitle._id,
      type: "TEXT",
      text: batchData._id,
    });

    memberFormData?.questions.forEach((question) => {
      if (question.type === "CHOICE" && question.visibility === false) {
        newResponses.push({
          question_id: genderId,
          type: "CHOICE",
          choice: gender.choices[0]._id,
        });
      } else if (question.type !== "CHOICE" && question.visibility === false) {
        newResponses.push({
          question_id: question._id,
          type: "TEXT",
          text: "",
        });
      }
    });
    const existingChoiceResponse = responses.find(
      (response) =>
        response.question_id === genderId && response.type === "CHOICE"
    );

    if (!existingChoiceResponse) {
      newResponses.push({
        question_id: genderId,
        type: "CHOICE",
        choice: gender.choices[0]?._id,
      });
    }

    const combinedResponses = [...responses, ...newResponses];

    return combinedResponses;
  }

  const handleFileUploadInputChange = (e) => {
    if (e.target.files.length > 0) {
      setSubmissionError((prevError) => ({
        ...prevError,
        missingFile: false,
      }));
    }

    setFile((prevValue) => {
      if (prevValue?.file_url) {
        URL.revokeObjectURL(prevValue.file_url);
      }
      return {
        ...prevValue,
        question_id: e.target.name,
        file_url: URL.createObjectURL(e.target.files[0]),
      };
    });
  };

  const handleRefNumberChange = (e) => {
    setFile((prevValue) => ({
      ...prevValue,
      question_id: e.target.name,
      text: e.target.value,
    }));
  };

  const fileUploadQuestion = memberFormData?.questions.find(
    (question) => question.type === "FILE_UPLOAD"
  );

  const handleChange = (event) => {
    const { name, value } = event.target;

    const nameQuestion =
      memberFormData &&
      memberFormData.questions.find(
        (question) => question.type === "TEXT" && question.title === "Name"
      );

    const nameQuestionId = nameQuestion && nameQuestion._id;

    if (name === nameQuestionId) {
      setSubmissionConfirmationData(value);
    }

    setResponses((prevResponses) => {
      const existingIndex = prevResponses.findIndex(
        (res) => res.question_id === name
      );

      if (existingIndex !== -1) {
        const updatedResponses = [...prevResponses];

        updatedResponses[existingIndex] = {
          question_id: name,
          type: name === genderId ? "CHOICE" : "TEXT",
          ...(name !== genderId && { text: value }),
          ...(name === genderId && {
            choice: value,
          }),
        };
        return updatedResponses;
      } else {
        return [
          ...prevResponses,
          {
            question_id: name,
            type: name === genderId ? "CHOICE" : "TEXT",
            ...(name !== genderId && { text: value }),
            ...(name === genderId && {
              choice: value,
            }),
          },
        ];
      }
    });
  };

  const [submissionConfirmationData, setSubmissionConfirmationData] =
    useState();

  function handleSubmit(event) {
    const formId = memberFormData._id;
    event.preventDefault();

    const formResponses = responseForAllQuestions();

    if (fileUploadQuestion.is_required === true && !file.file_url) {
      setSubmissionError((prevError) => ({ ...prevError, missingFile: true }));

      return;
    }

    postFormResponses(
      formId,
      formResponses,
      (successData) => {
        ref.current.close();

        setResponses([]);

        const inputs = formRef.current.querySelectorAll(
          "input, textarea, select"
        );

        inputs.forEach((input) => {
          input.value = "";
        });

        setFile({
          question_id: "",
          type: "FILE_UPLOAD",
          file_url: "",
          text: "",
        });

        submissionConfirmationRef.current.showModal();
      },
      (errorMessage) => {
        setSubmissionError((prevError) => ({
          ...prevError,
          formSubmissionError: true,
        }));
        console.error("Form submission failed:", errorMessage);
      }
    );
  }

  const { color, templateStyle, templateClassName } = useTheme();

  return (
    <>
      {" "}
      <dialog
        className={`${styles.batchesModal} ${styles[templateClassName]}`}
        ref={ref}
      >
        <header className={styles.batchesModalHeader}>
          <div className={styles.arrow}>
            <button
              className={styles.backBtn}
              onClick={() => ref.current.close()}
            >
              <FiArrowLeft />
            </button>
          </div>
          <div className={styles.batchImage}>
            <img src={batchData.image_url} />
          </div>
          <div className={styles.batchDetails}>
            <div className={styles.batchTitle}>
              {batchData.general_details.title}
            </div>
            <div className={styles.batchPrice}>
              Rs.{batchData.pricing.payable_amount}
            </div>
          </div>
        </header>
        <form
          className={styles.batchForm}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          {memberFormData &&
            memberFormData.questions.map((question) => {
              return (
                question.visibility && (
                  <div key={question._id}>
                    {question.type === "CHOICE" ? (
                      <div className={styles.batchInput} id="form">
                        <FormControl
                          fullWidth
                          className={styles.batchInput}
                          // sx={{
                          //   fontFamily: "inherit",
                          //   backgroundColor: "white",
                          // }}
                        >
                          {" "}
                          <InputLabel
                            id={`label-${question._id}`}
                            sx={{
                              // fontFamily: "inherit",
                              // backgroundColor: "white",
                              paddingInline: "4px",
                            }}
                          >
                            {question.title}
                          </InputLabel>
                          <Select
                            MenuProps={{
                              container: formRef.current,
                            }}
                            name={question._id}
                            labelId={`label-${question._id}`}
                            value={
                              responses.find(
                                (res) => res.question_id === question._id
                              )?.choice || ""
                            }
                            onChange={handleChange}
                            displayEmpty
                            sx={{ fontFamily: "inherit" }}
                          >
                            {question.choices.map((choice) => (
                              <MenuItem
                                key={choice._id}
                                value={choice._id}
                                sx={{ fontFamily: "inherit" }}
                              >
                                {choice.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    ) : question.type === "FILE_UPLOAD" ? (
                      <div className={styles.paymentModal}>
                        <div className={styles.modalHeader}>
                          <div className={styles.title}>Payment</div>
                        </div>
                        <div className={styles.paymentDetailsCard}>
                          <div className={styles.scan}>
                            <div>Scan QR code</div>
                            <div className={styles.scanImage}>
                              <img
                                src={paymentInfo ? paymentInfo.qr_code_url : ""}
                              />
                            </div>
                          </div>
                          <div className={styles.line}></div>
                          <div className={styles.or}>OR</div>
                          <div className={styles.bankDetails}>
                            <div>Bank account details</div>
                            <div className={styles.details}>
                              <ul className={styles.labels}>
                                {paymentInfo?.account_number && (
                                  <li>Account Number:</li>
                                )}
                                {paymentInfo?.ifsc_code && <li>IFSC Code:</li>}
                                {paymentInfo?.account_holder_name && (
                                  <li>Account Holder:</li>
                                )}
                              </ul>
                              <ul className={styles.values}>
                                <li>{paymentInfo?.account_number}</li>
                                <li>{paymentInfo?.ifsc_code}</li>
                                <li>{paymentInfo?.account_holder_name}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <input
                          placeholder="Enter payment reference number"
                          type="text"
                          maxLength="20"
                          className={styles.refNumber}
                          onChange={handleRefNumberChange}
                          name={question._id}
                          required
                        />
                        <div className={styles.upload}>
                          {file.file_url ? (
                            <div className={styles.previewImage}>
                              <IoMdClose
                                className={styles.close}
                                onClick={handleCancel}
                              />
                              <img
                                className={styles.image}
                                src={file.file_url}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <input
                            type="file"
                            ref={fileInputRef}
                            className={styles.uploadFiles}
                            onChange={handleFileUploadInputChange}
                            name={question._id}
                            accept="image/jpeg, image/png"
                          />
                          <label
                            style={{
                              color: color,
                              borderColor: color,
                            }}
                            onClick={handleUploadClick}
                            className={styles.uploadButton}
                            htmlFor={question._id}
                          >
                            {file.file_url ? "Change Image" : "Upload Image"}
                          </label>
                          <p
                            className={`${styles.text} ${
                              submissionError.missingFile &&
                              `${styles.errorText}`
                            }`}
                          >
                            *Upload your payment screenshot (max size: 5 MB)
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.batchInput}>
                        <TextField
                          label={question.title}
                          type={
                            question.type === "NUMBER"
                              ? "tel"
                              : question.type.toLowerCase()
                          }
                          sx={{ fontFamily: "inherit" }}
                          maxLength={question.max_value || undefined}
                          onChange={handleChange}
                          className={styles.input}
                          fullWidth
                          name={question._id}
                          required={question.is_required}
                          value={
                            question.title === "Batch"
                              ? batchData.general_details.title
                              : responses.find(
                                  (res) => res.question_id === question._id
                                )?.text || ""
                          }
                          disabled={question.title === "Batch"}
                          minLength={
                            question.type === "NUMBER" ? "10" : undefined
                          }
                          pattern={
                            question.type === "NUMBER" ? "[0-9]*" : undefined
                          }
                          InputProps={{
                            style: { fontFamily: "inherit" },
                          }}
                          InputLabelProps={{
                            style: { fontFamily: "inherit" },
                          }}
                        />
                      </div>
                    )}
                  </div>
                )
              );
            })}

          <div className={styles.button}>
            <button
              style={{ backgroundColor: color }}
              className={styles.submit}
              type="submit"
            >
              <div style={{ color: color }} className={styles.checkMark}>
                <MdCheck />
              </div>
              Submit
            </button>
            <SubmissionConfirmationPopup
              templateClassName={templateClassName}
              title={"Your Batch has been Booked!"}
              description={
                "After your payment is confirmed, you'll be able to join the batch"
              }
              identifiers={["Name", "Batch", "Amount", "Payment Status"]}
              details={[
                submissionConfirmationData,
                batchData.general_details.title,
                "Rs." + batchData.pricing.payable_amount,
                "Pending Verification",
              ]}
              closeButton
              onClose={() => setSubmissionConfirmationData("")}
              ref={submissionConfirmationRef}
            />
          </div>
        </form>
        <Snackbar
          open={submissionError.formSubmissionError}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          onClose={() =>
            setSubmissionError((prevError) => ({
              ...prevError,
              formSubmissionError: false,
            }))
          }
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Error submitting form! Please try again.
          </Alert>
        </Snackbar>
      </dialog>
    </>
  );
});

export default BatchesModal;
