import { forwardRef } from "react";
import styles from "./SubmissionConfirmationPopup.module.css";
import { IoMdCheckmark } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { createPortal } from "react-dom";
import { useTheme } from "../../../../beta-home/components/ThemeProvider";

const SubmissionConfirmationPopup = forwardRef(
  (
    {
      title,
      description,
      identifiers,
      details,
      closeButton,
      okButton,
      templateClassName,
      onClose,
    },
    ref
  ) => {
    const detailsArray = identifiers.map((item, index) => ({
      item,
      detail: details[index] || "",
    }));

    return createPortal(
      <dialog
        className={`${styles.submissionConfirmationPopup} ${
          styles[templateClassName] || ""
        }`}
        ref={ref}
      >
        {closeButton && (
          <div
            className={styles.closeButton}
            onClick={() => {
              ref.current.close();
              onClose();
            }}
          >
            <button className={styles.button}>
              <MdClose />
            </button>
          </div>
        )}
        <div className={styles.dialogContainer}>
          <div className={styles.check}>
            <IoMdCheckmark />
          </div>{" "}
          <div className={styles.title}>{title}</div>
          <div className={styles.desc}>{description}</div>
          <div className={styles.paymentDetails}>
            {/* <ul className={styles.identifier}>
              {identifiers.map(
                (identifier, index) =>
                  identifier && <li key={index}>{identifier}:</li>
              )}
            </ul>
            <ul className={styles.detail}>
              {details.map((detail, index) => (
                <li key={index}>{detail}</li>
              ))}
            </ul> */}

            <ul className={styles.identifier}>
              {detailsArray.map(
                (item, index) =>
                  item.detail && (
                    <li key={index}>
                      {item.item}:{" "}
                      <span className={styles.textBold}> {item.detail}</span>{" "}
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>
        {okButton && (
          <div className={styles.okButton}>
            <button
              className={styles.ok}
              onClick={() => {
                ref.current.close();
                onClose && onClose();
              }}
            >
              OK
            </button>
          </div>
        )}
      </dialog>,
      document.getElementById("root")
    );
  }
);

export default SubmissionConfirmationPopup;
