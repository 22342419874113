import instagramIcon from "../../../assets/social/instagram.svg";
import facebookIcon from "../../../assets/social/facebook.svg";
import twitterIcon from "../../../assets/social/twitter.svg";
import linkedinIcon from "../../../assets/social/linkedin.svg";
import youtubeIcon from "../../../assets/social/youtube.svg";

import styles from "./AboutUs.module.css";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLogoWhatsapp, IoMdMail } from "react-icons/io";
import { useTheme } from "../../ThemeProvider";

export default function AboutUs({ businessDetails, socialLinks, sales }) {
  function openLink(url) {
    if (url) {
      window.open(url, "_blank", "noopener, noreferrer");
    }
  }

  function formatAddress(address) {
    if (address.includes("\n") && address.includes(",")) {
      return address;
    }

    if (address.includes(",") && !address.includes("\n")) {
      return address.replace(/,\s*/g, ",\n");
    }

    if (
      !address.includes(",") &&
      !address.includes("\n") &&
      address.includes(" ")
    ) {
      return address.replace(/\s+/g, ",\n");
    }

    if (address.includes("\n") && !address.includes(",")) {
      return address.replace(/\n+/g, ",\n");
    }

    return address;
  }

  const theme = useTheme();
  const templateClassName = theme?.templateClassName || "";

  return (
    <footer
      className={`${styles[templateClassName]}`}
      style={{ marginTop: "0" }}
    >
      <div className={styles["about-us"]}>
        <div className={styles.contact}>
          <div className={styles["app-logo-title"]}>
            <img src={businessDetails.logo} alt="App Logo" />
            <span>{businessDetails.name}</span>
          </div>
          <div className={styles["contact-sales"]}>
            <div className={styles.title}>Contact Sales</div>

            {sales.mobileNumber && (
              <div className={styles.salesDetails}>
                <FaPhoneAlt />{" "}
                <span className={styles.details}>{sales.mobileNumber}</span>
              </div>
            )}

            {sales.email && (
              <div className={styles.salesDetails}>
                <IoMdMail />{" "}
                <span className={styles.details}>{sales.email}</span>
              </div>
            )}

            {sales.whatsappNumber && (
              <div className={styles.salesDetails}>
                <IoLogoWhatsapp />{" "}
                <span className={styles.details}>{sales.whatsappNumber}</span>
              </div>
            )}
          </div>
        </div>
        <div className={styles["address-div"]}>
          <span className={styles["address-title"]}>Address</span>
          <span className={styles.address}>
            {formatAddress(businessDetails.address)}
          </span>
          {businessDetails.email && (
            <div className={styles.emailAddress}>
              <span className={styles.emailDetails}>
                {businessDetails.email}
              </span>
            </div>
          )}
        </div>
        <div className={styles.social}>
          <span>Know us better</span>
          <div className={styles["social-icons"]}>
            {socialLinks.instagram && (
              <img
                src={instagramIcon}
                alt="Instagram"
                onClick={() => openLink(socialLinks.instagram)}
              />
            )}
            {socialLinks.facebook && (
              <img
                src={facebookIcon}
                alt="Facebook"
                onClick={() => openLink(socialLinks.facebook)}
              />
            )}
            {socialLinks.twitter && (
              <img
                src={twitterIcon}
                alt="Twitter"
                onClick={() => openLink(socialLinks.twitter)}
              />
            )}
            {socialLinks.linkedin && (
              <img
                src={linkedinIcon}
                alt="LinkedIn"
                onClick={() => openLink(socialLinks.linkedin)}
              />
            )}
            {socialLinks.youtube && (
              <img
                src={youtubeIcon}
                alt="YouTube"
                onClick={() => openLink(socialLinks.youtube)}
              />
            )}
          </div>
        </div>
        <p className={styles.poweredBy}>Powered by NucleApp</p>
      </div>
    </footer>
  );
}
