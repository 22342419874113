import axios from "axios";
import { getCustomerToken } from "../../shared/helper";

const YOGA_BASE_URL = "https://api.yogawav.com";

// if (token) {
//   localStorage.setItem("token", token);
// }

// const customerToken = localStorage.getItem("token");
// console.log(customerToken);

export const loginMember = async (email, onSuccess, onFailure) => {
  try {
    const response = await axios.post(
      YOGA_BASE_URL + "/customer/login",
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
    if (onFailure) {
      const errorMessage = error.response ? error.response.data : error.message;
      onFailure(errorMessage);
    }
  }
};

export const getInvoicesByCustomer = async (onSuccess, onFailure) => {
  try {
    const customerToken = getCustomerToken();
    if (!customerToken) {
      throw new Error("Customer token is not available.");
    }
    const response = await axios.post(
      YOGA_BASE_URL + "/invoices/customer",
      {},
      {
        headers: {
          Authorization: `Bearer ${customerToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
    if (onFailure) {
      const errorMessage = error.response ? error.response.data : error.message;
      onFailure(errorMessage);
    }
  }
};

export const getInvoiceByID = async (onSuccess, onFailure) => {
  try {
    const response = await axios.post(
      YOGA_BASE_URL + "/invoice/670a1b4a43a4c1e356ada821",
      {},
      {
        headers: {
          Authorization: `Bearer ${getCustomerToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
    if (onFailure) {
      const errorMessage = error.response ? error.response.data : error.message;
      onFailure(errorMessage);
    }
  }
};

export const getCustomerById = async (onSuccess, onFailure) => {
  try {
    const customerToken = getCustomerToken();
    if (!customerToken) {
      throw new Error("Customer token is not available.");
    }

    const response = await axios.get(YOGA_BASE_URL + "/customer", {
      headers: {
        Authorization: `Bearer ${customerToken}`,
        "Content-Type": "application/json",
      },
    });
    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
    if (onFailure) {
      const errorMessage = error.response ? error.response.data : error.message;
      onFailure(errorMessage);
    }
  }
};
