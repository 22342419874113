import AllWhyUs from "../modals/WhyUs/All-Why-Us/AllWhyUs.jsx";
import WhyUsModal from "../modals/WhyUs/WhyUsModal/WhyUsModal.jsx";
// import { ReadMore, ShowAll } from "../../../../shared/components/utils/utils";
import { ReadMore } from "../../../../shared/components/utils/utils";

import stylesTemplate1 from "./templates/template-1/WhyUs.module.css";
import stylesTemplate2 from "./templates/template-2/WhyUs.module.css";

import { useRef, useState } from "react";
import { useTheme } from "../../ThemeProvider.jsx";

export default function WhyUs({ whyUsList, template }) {
  const { color, templateName, templateClassName } = useTheme();

  const styles =
    templateName === "simpleui" || templateName === "edgefusion"
      ? stylesTemplate2
      : stylesTemplate1;

  const showAllWhyUsRef = useRef(null);
  const whyUsModalRef = useRef(null);

  const [selectedWhyUs, setSelectedWhyUs] = useState(null);

  function handleWhyUsModalOpen(whyUs) {
    whyUsModalRef.current.showModal();
    setSelectedWhyUs(whyUs);
  }

  function handleWhyUsModalClose() {
    whyUsModalRef.current.close();
  }

  return (
    <>
      <WhyUsModal
        ref={whyUsModalRef}
        onClose={handleWhyUsModalClose}
        whyUs={selectedWhyUs != null ? selectedWhyUs : {}}
      />

      <div
        className={`${styles.usp} ${styles[templateClassName] || ""}`}
        id="whychooseus"
      >
        <div className={styles.titleHeader}>
          <h2>Why Choose Us</h2>
        </div>
        <ul className={styles.uspcardsList}>
          {whyUsList.map((whyUs, index) => {
            return (
              <li
                key={`whyUs-${index}`}
                onClick={() => handleWhyUsModalOpen(whyUs)}
              >
                <WhyUsCard
                  imageUrl={whyUs.image_url || ""}
                  title={whyUs.title || ""}
                  desc={whyUs.description || ""}
                  template={template}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export function WhyUsCard({ imageUrl, title, desc, template }) {
  const { color, templateName, templateClassName } = useTheme();

  const styles =
    templateName === "simpleui" || templateName === "edgefusion"
      ? stylesTemplate2
      : stylesTemplate1;

  return (
    <div className={`${styles.uspCard} ${styles[templateClassName]}`}>
      <img src={imageUrl} alt="" />
      <span className={styles.title}>{title}</span>
      <span className={styles.desc}>{desc}</span>

      <span className={styles.readMore}>
        <ReadMore />
      </span>
    </div>
  );
}
