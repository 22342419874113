import { GoClockFill } from "react-icons/go";
import { formatTime } from "../../../../shared/helper";
import noImage from "../../../assets/no_image.png";
import { useState, useEffect } from "react";
import { getCustomerById } from "../../../services/profile-page-api";
import { CircularProgress } from "@mui/material";

import "./Batches.css";

export default function Batches() {
  const [batchesList, setBatchesList] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    getCustomerById(
      (response) => {
        setBatchesList(response.customer.batches);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader-wrapper">
          <CircularProgress variant="soft" thickness={4} />
        </div>
      ) : (
        <div className="dashboard-batches">
          <ul>
            {batchesList &&
              batchesList.map((batch, index) => {
                return (
                  <li key={`batch-${index}`}>
                    <BatchCard
                      imageUrl={batch.image_url}
                      title={batch.general_details.title}
                      desc={batch.general_details.description}
                      startTime={batch.schedule.time}
                      link={batch.location.streaming_info.link}
                    />{" "}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </>
  );
}

export function BatchCard({
  batch,
  imageUrl,
  title,
  desc,
  startTime,
  link,
  isRecent,
}) {
  return (
    <div className={`dashboard-batch-card ${isRecent ? "recent" : ""}`}>
      {imageUrl ? <img src={imageUrl} alt="" /> : <img src={noImage} alt="" />}
      <div className="batch-content">
        <span className="title">{title}</span>
        <hr />
        <span className="desc">{desc}</span>
        <div className="timing">
          <GoClockFill className="clock-icon" />
          <span>Timing: {formatTime(startTime)}</span>
        </div>
        {link && (
          <button
            onClick={() => window.open(link, "_blank", "noopener, noreferrer")}
          >
            Join now
          </button>
        )}
      </div>
    </div>
  );
}
