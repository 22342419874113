import AppDetails from "../app-details/AppDetails";
import QueryInput from "../query-input/QueryInput";
import styles from "./EnquiryModal.module.css";
import { forwardRef } from "react";
import Logo from "../../../../assets/enquiry-form/logo.svg";
import { MdClose } from "react-icons/md";
import { useTheme } from "../../../ThemeProvider";

const EnquiryModal = forwardRef(({ formId, onClose }, ref) => {
  const { templateClassName } = useTheme();

  return (
    <dialog
      className={`${styles.enquiryModal} ${styles[templateClassName]}`}
      ref={ref}
    >
      <div className={styles.modal}>
        <div className={styles.closeModal}>
          <button className={styles.close} onClick={onClose}>
            <MdClose />
          </button>
        </div>
        <div className={styles.header}>
          <div>
            <img src={Logo} />
          </div>
          <div>
            <h3>Bliss Yoga</h3>
          </div>
        </div>
        <div className={styles.queryModal}>
          <div className={styles.appDetailsSection}>
            <AppDetails />
          </div>
          <div className={styles.queryInputSection}>
            <QueryInput formId={formId} close={onClose} />
          </div>
        </div>
      </div>
    </dialog>
  );
});

export default EnquiryModal;
