import {
  getMemberDataLocal,
  setMemberDataLocal,
  useWindowWidth,
} from "../../../../shared/helper";
import "./Dashboard.css";

import UpcomingClasses from "../../layouts/upcoming-classes/UpcomingClasses";
import BatchesWrapperCard from "../../layouts/BatchesWrapperCard";
import InvoicesWrapperCard from "../../layouts/InvoicesWrapperCard";
import ContactUsCard from "../../layouts/ContactUsCard";
import InviteFriendsCard from "../../layouts/invite-friends-card/InviteFriendsCard";
import AboutUs from "../../../../beta-home/components/layouts/Footer/AboutUs";
import { useLocation } from "react-router-dom";
import Header from "../../layouts/Header/Header";
import { getCustomerToken } from "../../../../shared/helper";

import getLandingPageData from "../../../../beta-home/services/helper/get_landing_page_data";

export default function MemberDashboard() {
  const { isMobile } = useWindowWidth();
  const { search } = useLocation();

  const landingPageData = getLandingPageData(window.pageData);

  const { business, socialLinks, salesPerson, formIds, subdomain } =
    landingPageData;

  const authData = new URLSearchParams(search).get("auth");

  if (authData) {
    setMemberDataLocal(authData, false);
  }

  window.history.pushState({}, "", window.location.pathname);

  const memberData = getMemberDataLocal();

  // const customerToken = getCustomerToken();

  if (!memberData) {
    if (window.location.hostname.includes("localhost")) {
      window.location.href = `http://localhost:${window.location.port}/auth?sd=${subdomain}`;
    } else {
      window.location.href = `https://yogawav.com/auth?sd=${subdomain}`;
    }
    return null;
  }

  return (
    <div className="member-dashboard-wrapper">
      <div
        className={isMobile ? "mobile-member-dashboard" : "member-dashboard"}
      >
        <Header businessDetails={business} memberData={memberData} />
        <div className="content-area">
          <UpcomingClasses />
          <BatchesWrapperCard />
          <InvoicesWrapperCard formId={formIds.manualPaymentFormId} />
          <ContactUsCard businessDetails={business} />
          <InviteFriendsCard
            businessDetails={business}
            sales={salesPerson}
            socialLinks={socialLinks}
          />
        </div>
        <AboutUs
          sales={salesPerson}
          businessDetails={business}
          socialLinks={socialLinks}
        />
      </div>
    </div>
  );
}
