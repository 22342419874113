import { upcomingClassesList } from "../../../data/upcoming-classes-data";
import { formatTime, useWindowWidth } from "../../../../shared/helper";
import "./UpcomingClasses.css";
import { useState, useEffect } from "react";
import { getCustomerById } from "../../../services/profile-page-api";

export default function UpcomingClasses() {
  const { isMobile } = useWindowWidth();
  const [upcomingClasses, setUpcomingClasses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    getCustomerById(
      (response) => {
        setUpcomingClasses(response.customer.batches);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  const convertBatchesStartTime = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours + minutes / 60;
  };

  function formatDate(millis) {
    const today = new Date();
    const date = new Date(millis);

    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);

    if (today.getTime() === date.getTime()) {
      return "Today";
    }

    const options = {
      weekday: "short",
      day: "2-digit",
      month: "short",
    };

    let formattedDate = date.toLocaleDateString("en-US", options);

    formattedDate = formattedDate.replace(",", "");

    const [weekday, day, month] = formattedDate.split(" ");
    const finalFormattedDate = `${day} ${month}, ${weekday}`;

    return finalFormattedDate;
  }

  const getClassDates = (schedule) => {
    switch (schedule.session_frequency) {
      case "one-time":
        return handleOneTimeFrequency(schedule);
      case "weekly":
        return handleWeeklyFrequency(schedule);
      case "monthly":
        return handleMonthlyFrequency(schedule);
      default:
        return [];
    }
  };

  const handleOneTimeFrequency = (schedule) => {
    const oneTimedate = schedule.date + convertBatchesStartTime(schedule.time);
    return oneTimedate;
  };

  const handleWeeklyFrequency = (schedule) => {
    const weeklyActiveDays = schedule.days_active;
    const startDate = schedule.start_date;
    const endDate = schedule.end_date;
    const endDateMillis = endDate
      ? endDate + convertBatchesStartTime(schedule.time)
      : null;

    const startTime = convertBatchesStartTime(schedule.time);

    const getDayIndex = (day) => {
      const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
      return daysOfWeek.indexOf(day.toUpperCase());
    };

    function findWeeklyActiveDates(activeDays, startingDateMillis) {
      const activeDates = [];
      const startingDate = new Date(startingDateMillis);
      const startingDayIndex = startingDate.getDay();

      activeDays.forEach((day) => {
        const targetDayIndex = getDayIndex(day);

        let dayDiff = targetDayIndex - startingDayIndex;

        if (dayDiff < 0) dayDiff += 7;

        let weekDate = new Date(startingDate);
        weekDate.setDate(startingDate.getDate() + dayDiff);

        if (endDateMillis) {
          while (weekDate.getTime() <= endDateMillis) {
            activeDates.push(weekDate.getTime() + startTime);
            weekDate.setDate(weekDate.getDate() + 7);
          }
        } else {
          const maxDate = new Date(weekDate);

          maxDate.setFullYear(weekDate.getFullYear() + 3);

          const maxDateMillis = maxDate.getTime() + startTime;

          while (weekDate.getTime() <= maxDateMillis) {
            activeDates.push(weekDate.getTime() + startTime);
            weekDate.setDate(weekDate.getDate() + 7);
          }
        }
      });

      return activeDates;
    }

    return findWeeklyActiveDates(weeklyActiveDays, startDate);
  };

  const handleMonthlyFrequency = (schedule) => {
    const startingDate = schedule.start_date;
    const startingMonthlyDate = schedule.month_date;
    const startTime = convertBatchesStartTime(schedule.time);
    const endDate = schedule.end_date;

    const endDateMillis = endDate
      ? endDate + convertBatchesStartTime(schedule.time)
      : null;

    const generateMonthlyDates = (startingMonthlyDate) => {
      const monthlyDatesArray = [];
      monthlyDatesArray.push(startingDate + startTime);
      let monthDate = new Date(startingMonthlyDate);

      if (endDateMillis) {
        while (monthDate.getTime() <= endDateMillis) {
          monthlyDatesArray.push(monthDate.getTime() + startTime);
          monthDate.setMonth(monthDate.getMonth() + 1);
        }
      } else {
        const maxDate = new Date(monthDate);

        maxDate.setFullYear(monthDate.getFullYear() + 3);
        const maxDateMillis = maxDate.getTime() + startTime;

        while (monthDate.getTime() <= maxDateMillis) {
          monthlyDatesArray.push(monthDate.getTime());
          monthDate.setMonth(monthDate.getMonth() + 1);
        }
      }
      return monthlyDatesArray;
    };

    return generateMonthlyDates(startingMonthlyDate);
  };
  const array = upcomingClasses ? upcomingClasses : [];

  array.forEach((item) => {
    if (item.schedule) {
      item.schedule.classDate = getClassDates(item.schedule);
    }
  });

  const todayInMillis = new Date().getTime();

  const classDateDiff =
    array &&
    array.length !== 0 &&
    array.flatMap((item) => {
      const dates = Array.isArray(item.schedule.classDate)
        ? item.schedule.classDate
        : [item.schedule.classDate];

      return dates.map((date) => ({
        id: item._id,
        diff: date - todayInMillis,
      }));
    });

  const upcomingClassDate =
    array && array.length !== 0
      ? array.flatMap((item) => {
          const dates = Array.isArray(item.schedule.classDate)
            ? item.schedule.classDate
            : [item.schedule.classDate];
          return dates;
        })
      : [];

  const topThreeUpcomingClassesDate =
    upcomingClassDate && upcomingClassDate.length !== 0
      ? upcomingClassDate
          ?.filter((date) => date - todayInMillis > 0)
          .sort((a, b) => a - b)
          .slice(0, 3)
      : [];

  const formattedClassDate = topThreeUpcomingClassesDate.map((item) =>
    formatDate(item)
  );

  const calculateUpcomingClassTime = () => {
    const closestUpcoming = classDateDiff
      ? classDateDiff
          .filter((item) => item.diff > 0)
          .sort((a, b) => a.diff - b.diff)
      : [];

    const topThreeUpcoming =
      closestUpcoming.length > 3
        ? closestUpcoming.slice(0, 3)
        : closestUpcoming;

    return topThreeUpcoming;
  };

  const calculatedUpcomingClassesList = calculateUpcomingClassTime();

  const resultedArray =
    Array.isArray(calculatedUpcomingClassesList) &&
    calculatedUpcomingClassesList.length > 0
      ? calculatedUpcomingClassesList.map((item) =>
          upcomingClasses.find((obj) => obj._id === item.id)
        )
      : [];

  function addMinutes(timeStr, minutesToAdd) {
    const [hours, minutes] = timeStr.split(":").map(Number);

    const date = new Date();
    date.setHours(hours, minutes, 0, 0);

    date.setMinutes(date.getMinutes() + minutesToAdd);

    const newHours = String(date.getHours()).padStart(2, "0");
    const newMinutes = String(date.getMinutes()).padStart(2, "0");

    const newTime = `${newHours}:${newMinutes}`;

    return formatTime(newTime);
  }

  const resultedArrayWithDates = resultedArray.map((item, index) => ({
    ...item,
    formattedDate: formattedClassDate[index] || "",
  }));

  return (
    <>
      {resultedArrayWithDates && resultedArrayWithDates.length !== 0 && (
        <div className="upcoming-classes-card">
          <div className="card-title-bold">Upcoming Classes</div>
          <div className="upcoming-classes-list">
            {resultedArrayWithDates.map((item, index) => (
              <div className="upcoming-classes-list-item" key={index}>
                {!isMobile ? (
                  <>
                    <div className="date">{item.formattedDate}</div>
                    <div className="title">{item?.general_details?.title}</div>
                    <div className="time">
                      {`${formatTime(item?.schedule?.time)} - ${addMinutes(
                        item?.schedule?.time,
                        item?.schedule?.duration
                      )}`}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="time-and-date">
                      <div className="time">
                        {formatTime(item?.schedule?.time)} -
                        {addMinutes(
                          item?.schedule?.time,
                          item?.schedule?.duration
                        )}
                      </div>
                      <div className="date">{item.formattedDate} </div>
                    </div>
                    <div className="title">{item?.general_details?.title}</div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
