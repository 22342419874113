import axios from "axios";
import { BASE_URL } from "../../../shared/constants";
import getLandingPageData from "../helper/get_landing_page_data";

export async function getPaymentInfo(onSuccess, onFailure) {
  const landingPageData = getLandingPageData(window.pageData);

  const { userId } = landingPageData;
  try {
    const requestBody = {
      user_id: userId,
    };

    const response = await axios.post(
      `${BASE_URL}/getpaymentinfo`,
      requestBody,
      {}
    );

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
    if (onFailure) {
      const errorMessage = error.response ? error.response.data : error.message;
      onFailure(errorMessage);
    }
  }
}
